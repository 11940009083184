@import 'prism-themes/themes/prism-dracula.css';

@import './a4-page.css';
@import './back-button.css';
@import './big-header.css';
@import './blog-post.css';
@import './circle-button.css';
@import './globals.css';
@import './home-page.css';
@import './nav-item.css';
@import './post-summary.css';

:root {
  --measure: 60ch;

  --family-main: system-ui, sans-serif;
  --family-alt: Nunito, system-ui, sans-serif;
  --family-hand: 'Patrick Hand', Nunito, system-ui, sans-serif;
  --family-mono: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo,
    Courier, monospace;

  --color-primary: #377c85;

  /* https://github.com/robb-j/r0b-design/blob/master/src/styles/base/variables.sass */
  --color-magenta: #92374d;
  --color-berry: #8c5483;
  --color-liberty: #4a5899;
  --color-offBlue: #559cad;
  --color-silver: #c0b2aa;
  --color-deepKoamaru: #392b58;
  --color-bluePen: #00188a;

  --color-dark: #4a4a4a;
  --color-light: white;
  --color-anchor: #3273dc;
}
