/* 
  Circle button
*/
.circleButton {
  color: var(--color-light);
  cursor: pointer;
  line-height: 0;
  padding: 0.35em;
  border-radius: 100%;
  font-size: 1.5em;
  border: var(--s-5) solid rgba(255, 255, 255, 0.4);
  transition: transform 300ms ease;
}
.circleButton:hover {
  transform: scale(1.05);
}
.circleButton[data-accent='twitter'] {
  background-color: #1ca1f2;
}
.circleButton[data-accent='github'] {
  background-color: black;
}
.circleButton[data-accent='dockerhub'] {
  background-color: #066da5;
}
.circleButton[data-accent='instagram'] {
  background-image: linear-gradient(-135deg, #1400c8, #b900b4, #f50000);
}
.circleButton[data-accent='mastodon'] {
  background-color: #595aff;
}
.circleButton[data-accent='scholar'] {
  background-color: #4c90fe;
}
.circleButton[data-accent='strava'] {
  background-color: #f55308;
}
.circleButton[data-accent='letterboxd'] {
  background-color: #1f282f;
}
.circleButton[data-accent='rss'] {
  background-color: #f89939;
}
